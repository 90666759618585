import { isLocal } from '@magicschool/utils/nextjs/metadata';
import { getTeacherUrl } from '@magicschool/utils/nextjs/url';

export const edlinkLaunchUrl = (baseUrl: string) => {
  const edlinkSSOLogin = new URL('/sso/login', 'https://ed.link');
  edlinkSSOLogin.searchParams.set('client_id', process.env.NEXT_PUBLIC_EDLINK_CLIENT_ID);
  edlinkSSOLogin.searchParams.set('redirect_uri', edlinkRedirectUri(baseUrl));
  edlinkSSOLogin.searchParams.set('response_type', 'code');

  return edlinkSSOLogin.href;
};

export const edlinkRedirectUri = (baseUrl: string) => {
  return new URL('/handle-edlink', isLocal() ? getTeacherUrl() : baseUrl).href;
};
