'use client';

import type { EdlinkWidget } from '@/declarations/edlinkWidget';
import { edlinkRedirectUri } from '@/features/edlink/constants';
import { logger } from '@magicschool/logger';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
export const EdlinkWidgetClient = () => {
  const pathname = usePathname();
  const [widget, setWidget] = useState<EdlinkWidget | null>(null);
  const onLoad = useCallback(() => {
    let edlink_widget: EdlinkWidget;
    try {
      edlink_widget = new window.EdlinkWidget({
        client_id: process.env.NEXT_PUBLIC_EDLINK_CLIENT_ID,
        redirect_uri: edlinkRedirectUri(window.location.origin),
        colors: {
          primary: '#0088ff',
          background: '#181818',
          background_secondary: '#212121',
          background_tertiary: '#424242',
          text: '#ffffff',
          subtext: '#8395a7',
          button_text: '#ffffff'
        }
      });
      edlink_widget.activatePlugin({
        name: 'authentication'
      });
      setWidget(edlink_widget);
    } catch (error) {
      // TODO: Maybe notify the user of this error?
      logger.error('Failed to initialize Edlink widget:', error);
    }
    return () => {
      edlink_widget?.destroy();
    };
  }, []);
  useEffect(() => {
    if (!widget) return;
    if (pathname.includes('/auth/') || pathname.includes('/s/login')) {
      widget.show();
    } else {
      widget.hide();
    }
  }, [pathname, widget]);
  return <Script src="https://ed.link/widgets/edlink.js" onLoad={onLoad} data-sentry-element="Script" data-sentry-component="EdlinkWidgetClient" data-sentry-source-file="index.tsx" />;
};